<template>
  <b-card-code>

    <!-- forms -->
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="$emit('on-Submit', categoryForm)">

        <b-col md="12">
          <b-form-group
            label="Category Name"
            label-for="mc-category"
          >
            <validation-provider
              #default="{ errors }"
              name="Category"
              rules="required|max:50|min:3|pattern"
            >
              <template>
                <b-form-input
                  v-model="categoryForm.name"
                  placeholder="Bookings"
                  autocomplete="off"
                />
              </template>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- submit button -->
        <b-col md="12" class="demo-inline-spacing">
          <b-button
            :disabled="loading"
            variant="primary"
            type="submit"
          >
            Submit
            <div v-if="loading" class="spinner-border spinner-border-sm" />
          </b-button>
          <b-button

            variant="secondary"
            :to="{name: ((checkLoginRole() === RoleEnum.SuperAdmin) || checkAccess.view)?'category-list':'dashboard'}"
          >
            Cancel
          </b-button>
        </b-col>
      </b-form>
    </validation-observer>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormGroup, BForm, BCol, BButton, BFormInput
} from 'bootstrap-vue'
import {
  required
} from '@core/utils/validations/validations'
import checkLoginRole from '@/helpers/checkLoginRole'
import { RoleEnum } from '@/helpers/constant'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BForm,
    BCol,
    BButton,
    BFormInput
  },

  props: {
    categoryDetails: {
      type: Object,
      required: true
    },
    isLoaded: {
      type: Boolean,
      required: true
    },
  },

  data() {
    return {
      categoryForm: {
        name: this.categoryDetails?.name || ""
      },
      required,
      checkLoginRole,
      RoleEnum,
      loading: false,
      checkAccess: {},
    }
  },
  
  mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
  },

  methods: {
    async validationForm() {
      let valid = null
      await this.$refs.simpleRules.validate().then(success => {
        if (success) {
          valid = true
        } else {
          valid = false
        }
      })
      return valid
    }
  }
}
</script>
